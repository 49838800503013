.contact {
  width: 100%;
  min-height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: var(--dark);
  opacity: 0;
  animation: op .8s linear forwards;
  margin-top: 60px;
  
}

.contact .loader {
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent;
  z-index: 100;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader span {
  width: 50px;
  height: 50px;
  border: 5px solid  darkgray;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotate 1s linear infinite;
  
}

.loader span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border:  5px solid transparent;
  border-right-color: var(--green);
}

.titulo {
  color: var(--light-slate);
  font-size: 3rem;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 1.5rem;
}

.form {
  width: 680px;
  max-width: 95%;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 2rem 1rem;
  background: var(--light-navy);
  margin: 2rem 0;
}

.form input,
.form textarea {
  background: transparent;
  width: 100%;
  padding: 0.5rem;
  margin: 10px 0;
  font-size: 1rem;
  border: none;
  outline: none;
  border-bottom: 2px solid #fff;
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  /* border-radius: 5px; */
}

.form textarea {
  max-height: 200px;
  min-height: 100px;
  height: 100px;
  max-width: 100%;
  min-width: 100%;
  resize: none;
}
.form input:hover,
.form textarea:hover {
  border-color: var(--green);
}

.form button {
  padding: 0.5rem 1rem;
  color: var(--navy);
  background: var(--green);
  cursor: pointer;
  border: none;
  font-size: 1.25rem;
  border-radius: 26px;
  text-transform: uppercase;
  display: inline-block;
  align-self: flex-start;
}

.form .row {
  width: 100%;
  display: flex;
  gap: 2rem;
}
.form .col{
  width: 100%;
}

.form  label.required::after {
  content: "*";
  color: var(--green);
  position: absolute;
}

.form label{
  font-weight: bold;
  color: #fff;
}


@keyframes op {
  25%{
    opacity: .5;
  }
  50%{
    opacity: .5;
  }
  100%{
    opacity: 1;
  }
}

@keyframes rotate {
  to{
    transform: rotate(360deg);
  }
}