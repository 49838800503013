.container{
  width: 100%;
  height:calc( 100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  opacity: 0;
  animation: op .8s linear forwards;

}
.cards_container{
  margin: auto;
  width: 800px;
  min-height: 55vh;
  max-width: 100%;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}



@media screen and (max-width: 775px){
  .container::before{
    width: 100%;
    height: 600px;
    bottom: 0px;
  }
  
}
@keyframes op {
  25%{
    opacity: 0;
  }
  50%{
    opacity: .5;
  }
  100%{
    opacity: 1;
  }
}