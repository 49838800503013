.ButtonToTop{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--green);
  color: #fff;
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 5rem 1rem;
  display: flex;
  justify-content:center;
  align-items:center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.5s;
  font-size: 2rem;
  z-index: 100;
}

.active{
  opacity: 1;
}

