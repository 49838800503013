.container {
  width: 100%;
  margin-top: 60px;
  display: flex;
  min-height: 100vh;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.2s;
  opacity: 0;
  animation: op 0.8s linear forwards;
  /* background: #fff; */
  /* background: var(--dark); */
}



.cards_container{
  width: 960px;
  max-width: 100%;
  list-style: none;
  padding: 0px;
  margin: 50px 0px;
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
  position: relative;
  position: relative;
  align-content: space-evenly;
  justify-content: space-evenly;
  align-items: stretch;
  padding: 10px;

}

.title {
  color: #fff;
  font-size: 2.5rem;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 1.5rem;
}


.container p.first_text {
  text-align: center;
  padding: 0 1rem;
  color: var(--light-slate);
}


@media screen and (max-width: 775px) {
  .title {
    font-size: 1.5rem;
  }
}

@keyframes op {
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
