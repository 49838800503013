.footer {
  position: fixed;
  bottom: 0;
  right: 20px;
  writing-mode: vertical-rl;
  font-size: 14px;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 20px;
}

.line {
  width: 1px;
  height: 100px;
  background: var(--light-slate);
}

.footerMobile {
  width: 100%;
  min-height: 100px;
  display: none;
  justify-content: space-between;
  padding: 1rem 5rem;
  align-items: center;
  z-index: 100;
}
.footerMobile h4 {
  color: var(--light-slate);
}

.contact_container {
  display: flex;
  min-height: 100%;
  justify-content:center;
  align-items: center;
  gap: 1rem;
  text-align: center;
}

.contact_container p {
  font-size: 0.7rem;
}

.react_link {
  text-decoration: underline;
  color: #0050ff;
}

.folow p {
  display: flex;
  gap: .6rem;
}



.call,
.write,
.folow {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}

@media screen and (max-width: 825px) {
  .footer {
    display:  none;
  }
  .footerMobile {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  .copyright {
    margin: 0 1rem;
    /* order: 1; */
  }

  .contact_container {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 390px) {
  .footerMobile {
    /* flex-wrap: wrap; */
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
  }


  .contact_container {
    width: 100%;
    flex-wrap: nowrap;

  }
  .folow{
    gap: 1px;
  }
}
@media screen and (max-width: 340px) {
  .copyright{
    width: 100%;
    text-align: center;
    padding:0;
    margin: 0;
  }
  .footerMobile {
    /* flex-wrap: wrap; */
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: .5rem;
  }


  .contact_container {
    font-size: 12px;
    flex-wrap: wrap;
    justify-self: center;
  }
  .folow{
    gap: 1px;
  }

  .footer {
    display: none;
  }
}

