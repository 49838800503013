.container {
  width: 100%;
  min-height: calc(100vh - 60px);
  margin-top: 60px;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: op .8s linear forwards;
}

.container h2 {
  text-align: center;
  margin: 2.5rem 1rem;
  position: relative;
  display: flex;
  align-items: center;
  color: aliceblue;
}


.skills_container {
  width: 700px;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;

}


.arrow_guide {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 30px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: max(12px, 1vw);
  text-align: center;
  animation: move .3s ease infinite alternate;
  color: aliceblue;
}

.noactive {
  display: none;

}

.arrow_guide i {
  font-size: max(12px, 2vw);

}

@keyframes move {
  from {
    top: -70px;

  }

  to {
    top: -50px;

  }
}


@keyframes op {
  25% {
    opacity: 0;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}