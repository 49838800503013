* {
  box-sizing: border-box;
}

.card {
  width: 100%;
  height: 300px;
  /* height: 27vw; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: var(--green);
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  background: var(--light-navy);
  padding: .5rem 2rem;
  transition: all .3s linear;
}

.card:hover{
  transform: scale(1.05);
}

.card h5 {
  width: 100%;
  font-size: 1.5rem;
  text-align: left;
}

.card header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  color: var(--green);
  padding: 10px 0;
}
header .links {
  display: flex;
  gap: 10px;
  font-size: 1rem;
  color: #fff ;
}
.card header a:hover {
  color: var(--clear-blue);
}
.card p {
  text-align: left;
  text-wrap: wrap;
  text-overflow: ellipsis;
  font-size: 14px;
}

.card footer {
  width: 100%;
  display: flex;
}

.card  footer p {
  font-size: 12px;

}