

.card {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 6rem;

  /* border: 1px solid var(--green); */
  color: var(--slate);
  cursor: pointer;
  position: relative;
  background: var(--light-navy);
  /* filter: grayscale(100%); */
}



.card_tag {
  position: absolute;
  font-size: 0.8rem;
  /* background: #fff; */
  color: var(--green);
  text-align: center;
  top: 0px;
  opacity: 0;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: top .2s linear;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  border: 1px solid var(--green);
  text-shadow: none;
}

.card_tag::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--green);
  top: 35px;
}

.card:hover {
  /* color:#000; */
  transform: scale(1.03);
  text-shadow: 3px 3px var(--green);
  
}

.card:hover svg {
  filter: drop-shadow(3px 3px  var(--green));
  
}
.card:hover .card_tag {
  top: -45px;
  opacity: 1;
}

.noactive {
  display: none;
}