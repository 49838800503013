@import url("https://fonts.googleapis.com/css2?family=Redressed&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Redressed&family=Shippori+Antique+B1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap');
:root{
  --navy: #0a192f;
  --light-navy: #112240;
  --green: #64ffda;
  --slate: #8892b0;
  --light-slate: #ccd6f6;

}
a.active {
  color: var(--green)  !important;
  border-bottom:  1px solid;
}
canvas{
  max-height: 100vh !important;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  user-select: none;
  font-family: "Syne", sans-serif;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  letter-spacing: 0px;
  transition:  all .3s linear;
}

html,
body {
  width: 100%;
  background: var(--navy);
  min-height: 100%;
}


body::-webkit-scrollbar{
  display:none;
  width: 4px;
  background: var(--navy);

}

body{
  transition: all 0.5s;
}

body::-webkit-scrollbar-thumb{
  width: 4px;
  background: var(--green);
  border-radius: 25px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
p{
  color: var(--light-slate);
  font-weight: 200;
  font-family: "Comfortaa", sans-serif ;
  font-optical-sizing: auto;
}

.highlights {
  width: 100%;
  min-height: 400px;
  display:  flex;
  position: relative;
  /* background: #fff; */
  padding: 10px;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.highlights .card{
  width: 500px;
  margin: 50px;
  /* height: 300px; */
  text-align: center;
  font-size: max(1rem, 2vw);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.highlights .card .icon{
  font-size: 35px;
  color: var(--green);
}

@media screen and (max-width: 1024px) {
  a.active {
    color: var(--green)  !important;
    border: none
  }
}