a{
  color: var(--light-slate);
}
.header {
  width: 100%;
  min-width: 100%;
  height: 60px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 4rem;
  box-sizing: border-box;
  z-index: 100;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: fixed;
  top: 0;
  left: 0;
}

.sticky {
  box-shadow: 0 4px 2px -2px 10px #000;
  background: var(--navy);

}

.show {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.hide {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.header_logo {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 100;
  color: #fff;
}




.header_nav {
  display: flex;
  gap: 1rem;
}

.header_nav a {
  text-decoration: none;
  font-size: 1rem;
}


.header_nav a:hover {
  color: var(--green);
}




.menuBarBtn {
 
  align-items: center;
  border-radius: 50%;
  font-size: 2rem;
  cursor: pointer;
  z-index: 100;
  display: none;
}

.menuBarBtn::before{
  display: none;
}



@media screen and (max-width: 1024px) {
  .header {
    padding: 1rem 2rem;
  }
  .menuBarBtn {
    display: block;
  }
  .header_nav {
    position: fixed;
    width: 100%;
    min-height: 100vh;
    flex-direction: column;
    right: -100%;
    top: 0;
    background: var(--light-navy);
    align-items: center;
    justify-content: center;
    gap: 2rem;
    transition: right 0.3s ease;
    /* box-shadow: inset 1px 1px #000; */
  }

  .header_nav a {
    width: 100%;
    font-size: 1.5rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--light-slate);
  }



  .header_nav.active {
    right: 0;
  }
}
